import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './navigation.css';
import Logo from '../images/logo-1-tr.png';
import { useNavigate } from 'react-router-dom';

function Navigation() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);

  const history = useNavigate();
  const handleClick = () => {
    history('/waitlist');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Navbar>
      <Container className='nav-bar'>
        <Nav.Link href="/home"><img src={Logo} width='31px' height='31px' alt="" className='image-talentier' /></Nav.Link>
        <Nav className="me-right align-items-center">
          {!isWideScreen && (
            <Nav.Link href="/waitlist" className='navbar-text'>Contact Us</Nav.Link>
          )}
          {isWideScreen && (
            <>
              <Nav.Link href="/not-found" className='navbar-text'>Pricing</Nav.Link>
              <Nav.Link href="/not-found" className='navbar-text'>Start-ups</Nav.Link>
              <Nav.Link href="/not-found" className='navbar-text'>Enterprise</Nav.Link>
              <Nav.Link href="/waitlist" className='navbar-text'>Contact Us</Nav.Link>
              <Nav.Link href="/waitlist"><button className='link-nav-button-grey' onClick={handleClick}>Join the Feedback Loop</button></Nav.Link>
              <Nav.Link href="/waitlist"><button className='link-nav-button-grey' onClick={handleClick}>Join the waitlist</button></Nav.Link>

            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Navigation;
