import './footer.css'
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';

function Footer() {

  const history = useNavigate();
  const handleClick = () => {
    window.scrollTo(0, 0); 
    history('/waitlist');
    window.scrollTo(0, 0); 
  };

  return (
    <Container className='nav-bar-2'>
      <h1 className='manrope-heading'>Ready to streamline your interview process?</h1>
        <h4 style={{marginTop: '12px'}}>Learn more about how our interview outsourcing platform can benefit your organization.</h4><br/>
        <button className='link-nav-button-grey' onClick={handleClick}>Contact Us</button>
    </Container>
  );
}

export default Footer;
