import './main-image.css';
import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

// other imports remain the same

function WL() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [state, setState] = useState('');
  const [status,setStatus] = useState('Submit')

  // onChange handlers for input fields
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleWriteToCsv = async () => {
    let details = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      state: state,
    }
    // console.log(details);
    let response = await fetch("https://talentapp-3ullafem3a-ue.a.run.app/contact", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(details)
    })
    let result = await response.json();
    // alert(result.status);
  };

  // onSubmit handler for the form
  const handleSubmit = (e) => {
    e.preventDefault();
    handleWriteToCsv();
    setStatus('Submitted'); // Change status to 'Submitted' immediately on button click
    setTimeout(() => {
      setStatus('Submit'); // After 3 seconds, revert the status back to 'Submit'
    }, 5000);
    setFirstName('');
    setLastName('');
    setEmail('');
    setState('');
  };

  return (
    <div className="waitlist-form">
    <Card className="main-card-3">
      <Card.Body className="card-body-2">
        <Card.Text className='manrope-heading' style={{color: 'black', fontSize: '1.5em', marginBottom: '4px'}}>
        Almost there! Hang in there a little longer.
        </Card.Text>
        <Card.Text style={{color: 'black', fontSize: '1em'}}>
        Join our waitlist now to receive exclusive updates, insider news, and early access to our latest offerings.
        </Card.Text>
        {/* form starts here. */}
        <div className='form-waitlist'>
        <Form>
        <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label className='form-label'>First name</Form.Label>
          <Form.Control type="name" placeholder="First name" value={firstName} onChange={handleFirstNameChange} style={{ boxShadow: 'none', fontSize: '14px', borderColor: '#f4f4f4'}}/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label className='form-label'>Last name</Form.Label>
          <Form.Control type="name" placeholder="Last name" value={lastName} onChange={handleLastNameChange} style={{ boxShadow: 'none', fontSize: '14px', borderColor: '#f4f4f4'}}/>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label className='form-label'>Email</Form.Label>
          <Form.Control type="email" placeholder="example@talentier.com" value={email} onChange={handleEmailChange} style={{ boxShadow: 'none', fontSize: '14px', borderColor: '#f4f4f4'}}/>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label className='form-label'>Phone Number</Form.Label>
        <Form.Control placeholder="(+1) ..." value={state} onChange={handleStateChange} style={{ boxShadow: 'none', fontSize: '14px', borderColor: '#f4f4f4'}}/>
      </Form.Group>
      <Button type="submit" className='link-nav-button-grey' onClick={handleSubmit} style={{color: 'black', marginTop: '15px', marginBottom: '-20px', fontSize: '14px', minHeight: '40px', maxHeight: '40px', minWidth: '100px', maxWidth: '100px'}}>
        {status}
      </Button>
    </Form>
        </div>
    </Card.Body>
    </Card>
    </div>
    
  );
}

export default WL;