import Card from 'react-bootstrap/Card';
import './main-image.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const cardContents = [
  {
    title: 'Interviewer feedback and analysis',
    text: 'Gain insights from interviewer feedback and analysis to improve your hiring process.',
  },
  {
    title: 'Seamless scheduling',
    text: 'Our platform allows for easy coordination of interview schedules, ensuring that everything runs smoothly.',
  },
  {
    title: 'Integrated interview platform',
    text: 'Everything you need to manage the interview process in one place.',
  },
  {
    title: 'Streamlined hiring dashboard',
    text: 'Keep track of your hiring pipeline and manage everything in one place',
  },
];

const cardContents2 = [
  {
    text: 'Add/Upload your Applicants →',
  },
  {
    text: 'We schedule interviews →',
  },
  {
    text: 'Undergo thorough standardized testing →',
  },
  {
    text: 'You make the final decision ♡',
  },
];

function MainImage() {
  useEffect(() => {
    // Add pointermove event listener
    const handlePointerMove = (e) => {
      const { currentTarget: el, clientX: x, clientY: y } = e;
      const { top: t, left: l, width: w, height: h } = el.getBoundingClientRect();
      el.style.setProperty('--posX', x - l - w / 2);
      el.style.setProperty('--posY', y - t - h / 2);
    };

    document.body.addEventListener('pointermove', handlePointerMove);

    // Cleanup function
    return () => {
      document.body.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);
  const history = useNavigate();
  const handleClick = () => {
    window.scrollTo(0, 0);
    history('/waitlist');
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Card className="main-card">
        <Card.Body className="card-body">
          <Card.Text className='manrope-heading' style={{ color: 'white', fontSize: '3em' }}>
            Outsource your interviews
          </Card.Text>
          <Card.Text style={{ color: 'white', fontSize: '1em', marginBottom: '0px' }}>
            Save time and resources by leveraging our platform to connect with qualified candidates.
            {/* We're here to help you get the best people in your company. */}
          </Card.Text>
          <div class="button-container">
            <button class="arrow-button" onClick={handleClick} style={{ fontSize: '1em', minWidth: '10vw', maxWidth: '45vw', padding: '15px', marginRight: '15px', marginTop: '20px' }}>Join the waitlist<span class="arrow"></span>
            </button>
            <button class="arrow-button" onClick={handleClick} style={{ fontSize: '1em', minWidth: '10vw', maxWidth: '55vw', padding: '15px', marginRight: '13px', marginTop: '20px' }}>Join the feedback loop<span class="arrow"></span>
            </button>
          </div>

        </Card.Body>
      </Card>
      {/* second card here */}
      <Card className="main-card-2">
        <Card.Body className="card-body-2">
          <Card.Text className='manrope-heading' style={{ color: 'black', fontSize: '1.5em', marginBottom: '4px' }}>
            Why use us?
          </Card.Text>
          <Card.Text style={{ color: 'black', fontSize: '1em' }}>
            Focus on what you do best. We'll take care of the rest.
          </Card.Text>
          <Row xs={1} md={4} className="g-4">
            {cardContents.map((content, idx) => (
              <Col key={idx}>
                <Card style={{ border: 'none' }}>
                  <Card.Body className='grid-boxes'>
                    <Card.Title className="text-left align-top non-selectable" style={{ fontWeight: 'bold' }}>{content.title}</Card.Title>
                    <Card.Text className="text-left non-selectable">
                      {content.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Card.Text className='manrope-heading' style={{ color: 'black', fontSize: '1.5em', marginBottom: '4px', marginTop: '30px' }}>
            How it works
          </Card.Text>
          <Card.Text style={{ color: 'black', fontSize: '1em' }}>
            We take care of everything so you can focus on growing your team.
          </Card.Text>
          <Row xs={1} md={4} className="g-4">
            {cardContents2.map((content2, idx) => (
              <Col key={idx}>
                <Card style={{ border: 'none' }}>
                  <Card.Body className='grid-boxes'>
                    <Card.Text className="text-left non-selectable">
                      {content2.text}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default MainImage;