// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from './components/navigation';
import Footer from './components/footer';
import Home from './components/main-image';
import Waitlist from './components/waitlist';
import NotFound from './components/404';
import Feedback from './components/feedbackLoop';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate page load time
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 250); // Adjust the timeout as per your need

    // Cleanup function
    return () => clearTimeout(timeout);
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {isLoading && (
          <div className="spinner-container">
            {/* Add your spinner component here */}
            <div className="spinner" />
          </div>
        )}

        <Navigation />
        <header className="App-header">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="" element={<Home />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/not-found" element={<Waitlist />} />
            <Route path="/feedback" element={<Waitlist />} />
          </Routes>
        </header>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
